<!-- eslint-disable vue/valid-v-bind -->
<template>
<div>
    <div class="flex text-center bg-primary p-4 rounded my-2 justify-between sticky border-dashed border border-white border-opacity-25" style="top:108px">
        <span>Node ID</span>
        <span>Node Title</span>
        <span>Datacenter ID</span>
        <span>Datacenter Title</span>
        <span>Ip</span>
        <span>Active Ip</span>
        <span>In Active Ip</span>
        <span>Used Ip</span>
        <span>Filter Ip</span>
        <span>Active Vm</span>
        <span>ShutDown Vm</span>
        <span>Suspended Vm</span>

    </div>
    <div :key="index" v-for="(report, index) in reports" :class="index % 2 === 0 ? 'bg-primary' : 'bg-primaryLight'" class="flex text-center p-4 rounded my-2 justify-between items-center border border-primary border-solid">
    <span class="w-16">{{ report['NodeID'] }}</span>
    <span class="w-16">{{ report['NodeTitle'] }}</span>
    <span class="w-16">{{ report['DatacenterID'] }}</span>
    <span class="w-16">{{ report['DatacenterTitle'] }}</span>
    <span class="w-16">{{ report['NodeTotalIp'] }}</span>
    <span class="w-16">{{ report['NodeTotalActiveIp'] }}</span>
    <span class="w-16">{{ report['NodeTotalInActiveIp'] }}</span>
    <span class="w-16">{{ report['NodeTotalUsedIp'] }}</span>
    <span class="w-16">{{ report['NodeTotalFilterIp'] }}</span>
    <span class="w-16">{{ report['TotalActiveVm'] }}</span>
    <span class="w-16">{{ report['TotalShutDownVm'] }}</span>
    <span class="w-16">{{ report['TotalSuspendedVm'] }}</span>
</div>

</div>
</template>

<script>

export default {

data: () => ({
    reports:{},
}),

methods: {
    getReport() {
         this.$http
         .get('/admin/cloud/node/details')
         .then((res) => {
            this.reports = res.data;
         })
    }
},

created() {
    this.getReport()
}

}

</script>